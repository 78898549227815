@use '../../../common/styles/_mixins.scss' as *;

.BaseTypography {
  // profile title
  &_pt {
    color: #192a3e;
    font: 600 2.8rem var(--main-font-montserrat);
    line-height: 3.2rem;

    @include w768 {
      font-size: 2.4rem;
    }

    @include w480 {
      font-size: 2.2rem;
    }
  }

  // profile subtitle
  &_ps {
    color: #192a3e;
    font: 500 1.8rem var(--main-font-montserrat);
    line-height: 3.2rem;
  }

  // public route title
  &_prt {
    color: #333333;
    font: 600 2.4rem var(--main-font-montserrat);
    line-height: 3.2rem;

    @include w768 {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }

    @include w480 {
      font-size: 2rem;
      line-height: 2.16rem;
    }
  }

  // admin title
  &_at {
    color: #000000;
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 3.2rem;

    @include w1024 {
      font-size: 2.4rem;
    }

    @include w768 {
      font-size: 2.2rem;
      line-height: 2.42rem;
    }
  }

  // modal subtitle
  &_ms {
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.52rem;

    @include w768 {
      font-size: 1.4rem;
      line-height: 1.96rem;
    }
  }
}
