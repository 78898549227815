@use '../../../common/styles/_mixins.scss' as *;

.AdminMailingPage {
  display: grid;
  grid-template-columns: 1fr;

  // HEADER
  &__button {
    width: 20.4rem;

    @include w768 {
      width: 13.1rem;
      font-size: 1.4rem !important;
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }

    @include w480 {
      width: 13.1rem;
      font-size: 1.4rem !important;
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
  }

  // BODY
  &__body {
    margin-top: 3rem;

    @include w1024 {
      padding-right: 3rem;
    }

    @include w768 {
      overflow: hidden;
      overflow-x: scroll;
      padding-right: 3rem;
    }

    @include w480 {
      overflow: hidden;
      overflow-x: scroll;
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }

  &__table {
    @include w768 {
      width: 78.4rem;
    }

    @include w480 {
      width: 78.4rem;
    }
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
  }

  &__control {
    cursor: pointer;
    transition: var(--main-transition);
    max-height: 2rem;
    min-height: 2rem;
    max-width: 2rem;
    min-width: 2rem;
  }

  &__control:nth-child(1n + 2) {
    margin-left: 1.8rem;
  }

  &__control:hover {
    opacity: 0.4;
  }

  // FOOTER
  &__footer {
    margin-top: 2rem;
  }
}
