@use "../../../common/styles/_mixins.scss" as *;

.MaterialPage {
  // HEADER
  &__information {
    align-items: center;
    display: grid;
    grid-template-columns: auto 70%;
    column-gap: 4rem;
    margin-top: 2rem;

    @include w480 {
      grid-template-columns: auto;
    }
  }

  &__information_item:nth-child(2) {
    margin-top: 2rem;
    justify-self: end;
  }

  &__header_bot {
    margin-top: 2rem;
  }

  &__title {
    max-width: 89.2rem;
    font: 700 3.8rem var(--main-font-montserrat);
    line-height: 4.56rem;

    @include w1024 {
      font-size: 3.4rem;
      line-height: 4.08rem;
    }

    @include w768 {
      font-size: 3.2rem;
      line-height: 3.84rem;
    }

    @include w480 {
      font-size: 2rem;
      line-height: 2.6rem;
    }
  }

  // HEADER => USER
  &__list {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    @include w480 {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__item:nth-child(1) {
    align-items: center;
    display: flex;
  }

  &__author_avatar {
    max-height: 4rem;
    min-height: 4rem;
    max-width: 4rem;
    min-width: 4rem;
    border-radius: 50%;
  }

  &__author_name {
    margin-left: 1.5rem;
    font: 600 1.8rem var(--main-font-montserrat);
    line-height: 1.9rem;
  }

  &__author_all_article_link {
    @include w480 {
      margin-top: 1rem;
      font-size: 1.2rem;
    }
  }

  // BODY
  &__body {
    margin-top: 2.7rem;
  }

  &__body_item:nth-child(2) {
    margin-top: 3rem;

    @include w768 {
      margin-top: 2rem;
    }
  }

  &__body_description {
    word-wrap: break-word;
    font: 500 1.8rem var(--main-font-montserrat);
    line-height: 2.8rem;
    color: #434252;
    white-space: pre-wrap;

    @include w480 {
      font-size: 1.2rem;
      line-height: 1.92rem;
    }
  }

  // FOOTER
  &__footer {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    // justify-content: space-between;
    margin-top: 6.2rem;

    @include w480 {
      grid-template-columns: 1fr 1fr;
      gap: 2rem 0;
    }
  }

  &__footer_item:nth-child(1) {
    grid-column: 1 / 2;
    justify-self: start;

    @include w480 {
      grid-column: 1 / 2;
    }
  }

  &__footer_item:nth-child(2) {
    grid-column: 2 / 3;
    justify-self: center;

    @include w480 {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
    }
  }

  &__footer_item:nth-child(3) {
    grid-column: 3 / 4;
    justify-self: end;

    @include w480 {
      grid-column: 2 / 3;
    }
  }

  &__footer_item_hide {
    display: none;
  }

  &__footer_arrow {
    align-items: center;
    cursor: pointer;
    display: flex !important;
  }

  &__footer_arrow:hover > * {
    transition: var(--main-transition);
    color: var(--main-color-orange);
  }

  &__footer_arrow_icon:nth-child(1) {
    margin-right: 1.2rem;
  }

  &__footer_arrow_icon:nth-child(2) {
    margin-left: 1.2rem;
  }

  &__footer_arrow_text {
    font: 400 1.6rem var(--main-font-montserrat);
    line-height: 1.9rem;

    @include w480 {
      font-size: 1.4rem;
    }
  }

  &__footer_button {
    width: 26rem;

    @include w480 {
      width: 22rem;
      padding: 1rem 0 !important;
    }
  }
}
