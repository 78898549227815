@use '../../../../common/styles/_mixins.scss' as *;

.ProfileOrganization {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.6rem 4rem;

  @include w1024 {
    gap: 2.6rem 2rem;
  }

  @include w768 {
    gap: 2.8rem 2rem;
  }

  @include w480 {
    grid-template-columns: 1fr;
    gap: 1.6rem 0;
  }

  &>*:nth-child(9),
  &>*:nth-child(10),
  &>*:nth-child(11),
  &>*:nth-child(14),
  &>*:nth-child(15),
  &>*:nth-child(16) {
    grid-column: 1 / 3;
  }

  &>*:nth-child(n) {
    @include w480 {
      grid-column: 1 / 3;
    }
  }
}
