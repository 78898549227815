@use '../../../common/styles/_extends.scss' as *;
@use '../../../common/styles/_mixins.scss' as *;

.OptionList {
  min-width: 100%;
  padding: 0 0 0 1.6rem;

  &__input {
    align-items: center;
    display: flex;
    overflow: hidden;
    max-width: 90%;
    min-height: 1.84rem;

    @include w768 {
      max-width: 90%;
    }

    @include w480 {
      max-width: 88%;
    }
  }

  &__single_value {
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 90%;

    @include w768 {
      font-size: 1.4rem;
    }

    @include w480 {
      font-size: 1.4rem;
    }
  }

  &__list_wrapper {
    background-color: var(--main-color-white);
    border: 0.1rem solid #b6b4bf;
    border-radius: 0.8rem;
    padding: 1.6rem 1rem 3.4rem 1rem;
    position: absolute;
    top: 5.5rem;
    left: 0;
    max-height: 16.8rem;
    width: 100%;
    z-index: 99999;
  }

  &__list {
    @extend %base-scroll;
    display: grid;
    max-height: 12.2rem;
    overflow: hidden;
    overflow-y: scroll;
    row-gap: 1rem;
  }
}
