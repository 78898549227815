@use '../../common/styles/_funcs.scss' as *;
@use '../../common/styles/_mixins.scss' as *;

.MailingSubscription {
  background-color: rgba(197, 147, 104, 0.6);
  background-image: url('../../common/images/w1440/image-brown-elipse-about-w1440.webp'),
    url('../../common/images/w1440/image-orange-elipse-about-w1440.webp');
  background-repeat: no-repeat, no-repeat;
  background-position: rem(69.9rem, 144rem) 100%, rem(86.9rem, 144rem) 100%;
  color: #333333;
  padding: 4rem 0 5rem 0;

  @include w1024 {
    background-position: rem(69.9rem, 144rem) 100%, rem(86.9rem, 144rem) 100%;
  }

  @include w768 {
    background-position: 96% 100%, 100% 100%;
    background-image: url('../../common/images/w768/image-brown-elipse-about-w768.webp'),
      url('../../common/images/w768/image-orange-elipse-about-w768.webp');
  }

  @include w480 {
    background-image: unset;
  }

  &__inner {
    display: grid;
    row-gap: 2.2rem;

    @include w480 {
      row-gap: 1rem;
    }
  }

  &__title {
    font: 700 2.4rem var(--main-font-montserrat);
    line-height: 3rem;

    @include w768 {
      font-size: 2rem;
    }

    @include w480 {
      font-size: 1.6rem;
    }
  }

  &__description {
    @include w480 {
      display: grid;
      row-gap: 1rem;
    }
  }

  &__text {
    font: 500 1.4rem var(--main-font-montserrat);
    line-height: 2.4rem;

    @include w768 {
      font-size: 1.2rem;
    }
  }

  &__mailing {
    @include w480 {
      margin-top: 1rem;
    }
  }
}

.MailingSubscriptionInput {
  display: flex;
  max-width: 48.8rem;

  @include w768 {
    max-width: 42.2rem;
  }

  &__field {
    border-top-left-radius: 0.8rem !important;
    border-bottom-left-radius: 0.8rem !important;
    font-size: 1.4rem !important;
    min-height: 100% !important;

    @include w480 {
      font-size: 1.2rem !important;
    }
  }

  &__button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    min-width: 16.8rem !important;
    font-size: 1.5rem !important;

    @include w480 {
      max-width: 13.7rem !important;
      min-width: 13.7rem !important;
    }
  }
}
