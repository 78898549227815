.BaseOutlinedSelect {

  width: 100%;
  min-width: 9rem;
  position: relative;

  &__input {
    align-items: center;
    cursor: pointer;
    color: #109CF1;
    display: flex;
    justify-content: space-between;
  }

  &__item,
  &__input_value {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  &__list {
    position: absolute;
    width: 100%;
    margin-top: .5rem;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: .5rem;
    background-color: var(--main-color-white);
    z-index: 99999;
  }

  &__item {
    cursor: pointer;
    transition: var(--main-transition);
  }

  &__item:hover {
    color: #109CF1
  }
}
