@use '../../../common/styles/_mixins.scss' as *;

.FormCardSubtitle {
  color: #999999;
  font: 400 1.6rem var(--main-font-montserrat);
  line-height: 1.719rem;

  @include w480 {
    font-size: 1.4rem;
  }
}
