@use '../../../common/styles/_mixins.scss' as *;

.MaterialsPage {
  &__header {
    display: grid;
    row-gap: 3rem;
  }

  &__header_top {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  &__header_bot {
    column-gap: 1rem;
    display: grid;
    grid-template-columns: 21.6rem 32rem;

    @include w480 {
      grid-template-columns: 1fr;
      row-gap: 1rem;
    }
  }

  &__body {
    margin-top: 4.6rem;

    @include w1024 {
      margin-top: 5.62rem;
    }

    @include w768 {
      margin-top: 4rem;
    }

    @include w480 {
      margin-top: 3rem;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, minmax(auto, 35.8rem));
    gap: 2.3rem 1.9rem;

    @include w1024 {
      grid-template-columns: repeat(2, 35.6rem);
      gap: 2rem;
    }

    @include w768 {
      grid-template-columns: repeat(2, 35.6rem);
      gap: 2rem;
    }

    @include w480 {
      grid-template-columns: 34rem;
      justify-items: center;
      justify-content: center;
      gap: 1.6rem 0;
    }
  }

  &__loader {
    display: flex;
    justify-content: center;
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin: 7.8rem 0 0 0;
  }
}
