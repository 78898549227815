@use "../../common/styles/_mixins.scss" as *;

.ImageUploader {
  align-items: center;
  column-gap: 2.3rem;
  display: grid;
  grid-template-columns: auto 1fr;

  @include w480 {
    column-gap: 1rem;
  }

  &__text {
    font: 400 1.6rem var(--main-font-montserrat);
    line-height: 2.4rem;
    color: #b6b4bf;

    @include w768 {
      font-size: 1.4rem;
    }

    @include w480 {
      font-size: 1.2rem;
    }
  }

  &__item:nth-child(2) {
    max-width: 25.2rem;
  }
}

.UploadImageWrapper {
  background-color: rgba($color: #b6b4bf, $alpha: 0.15);
  border-radius: .8rem;
  cursor: pointer;
  position: relative;

  &_preview {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__inner_preview {
    max-height: 4.333rem;
    min-height: 4.333rem;
    max-width: 4.333rem;
    min-width: 4.333rem;
  }

  &,
  &__image_uploaded {
    max-height: 11.2rem;
    min-height: 11.2rem;
    max-width: 11.2rem;
    min-width: 11.2rem;
    object-fit: cover;
  }

  &__image {
    border-radius: .8rem;
  }

  &__input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
}
