.Alert {

  position: fixed;
  top: 1rem;
  left: calc((100vw - 34rem) / 2);

  &__inner {
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 1rem;
    background-color: #F5F7FA;
    border-radius: 1rem;
    max-width: 44.1rem;
    min-width: 34rem;
    padding: 1.6rem;
  }

  &__text {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
  }

  &__icon {
    cursor: pointer;
  }
}
