.MobileMenu {
  background-color: var(--main-color-white);
  // position: absolute;
  top: 8.089rem;
  height: 100%;
  left: 0;
  width: 100%;
  // z-index: 99999;

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 1rem;
    row-gap: 2.4rem;
  }

  &__list:nth-child(1) {
    // margin-top: 7rem;
    margin-top: 3rem;
  }

  &__list:nth-child(2) {
    padding-top: 2.4rem;
  }

  &__list:nth-child(1),
  &__list:nth-child(2) {
    border-bottom: .1rem solid rgba($color: #000000, $alpha: .1);
    padding-bottom: 2.4rem;
  }

  &__list:nth-child(3) {
    row-gap: 2rem;
    padding-top: 2.4rem;
  }

  &__list:nth-child(3)>*>* {
    font-size: 1.8rem !important;
  }

  &__link {
    display: grid;
    grid-template-columns: 1fr auto;
  }
}
