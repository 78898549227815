@use '../../common/styles/_mixins.scss' as *;
@use '../../common/styles/_funcs.scss' as *;

.OrganizationUser {
  display: grid;
  column-gap: 4.6rem;
  grid-template-columns: auto 1fr;

  @include w1024 {
    column-gap: 2.3rem;
  }

  @include w768 {
    column-gap: 1.1rem;
  }

  @include w480 {
    grid-template-columns: 1fr;
  }

  &__header {
    @include w480 {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 0 2.4rem;
    }
  }

  &__info {
    @include w480 {
      margin-top: 1rem;
    }
  }

  &__info_header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 68rem;
  }

  &__requests_link {
    display: flex;
    align-items: center;
  }

  &__requests_icon {
    max-height: 1.5rem !important;
    min-height: 1.5rem !important;
    max-width: 1.5rem !important;
    min-width: 1.5rem !important;
    margin-left: 1rem;
  }

  &__content {
    padding-right: 2rem;

    @include w1024 {
      max-width: 68rem;
    }

    @include w768 {
      max-width: 48rem;
    }

    @include w480 {
      max-width: 100%;
      padding: 0;
    }
  }

  &__avatar {
    border-radius: 0.8rem;
    max-height: 8.04rem;
    min-height: 8.04rem;
    max-width: 8.04rem;
    min-width: 8.04rem;
    object-fit: cover;

    @include w768 {
      max-height: 4rem;
      min-height: 4rem;
      max-width: 4rem;
      min-width: 4rem;
    }

    @include w480 {
      max-height: 5rem;
      min-height: 5rem;
      max-width: 5rem;
      min-width: 5rem;
    }
  }

  &__address {
    align-items: center;
    column-gap: 0.933rem;
    display: grid;
    grid-template-columns: auto 1fr;
    margin-top: 2.1rem;

    @include w480 {
      margin-top: 0.8rem;
    }
  }

  &__address_icon,
  &__address_name {
    color: #101f44;
  }

  &__address_name {
    font: 400 1.4rem var(--main-font-montserrat);
  }

  &__subtitle {
    margin-top: 4.3rem;

    @include w480 {
      margin-top: 2.7rem;
    }
  }

  &__data {
    max-width: 68rem;
    margin-top: 3rem;

    @include w480 {
      margin-top: 2rem;
    }
  }

  &__buttons {
    margin-top: 5rem;
    column-gap: 2.4rem;
    display: grid;
    grid-template-columns: minmax(auto, 21.3rem) minmax(auto, 18.5rem);

    @include w480 {
      margin-top: 2.3rem;
    }
  }
}
