@use '../../common/styles/_mixins.scss' as *;

.DefaultAdmin {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;

  &__inner {
    column-gap: 6.6rem;
    display: grid;
    grid-template-columns: auto 1fr;
    width: 100%;

    @include w1024 {
      column-gap: 3rem;
    }

    @include w768 {
      column-gap: 3rem;
    }

    @include w480 {
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
    }
  }

  &__body {
    padding: 8rem 6rem 8rem 0;
    max-width: 118rem;
    height: 100%;

    @include w1024 {
      padding: 7rem 0 7rem 0;
    }

    @include w768 {
      padding: 7rem 0 7rem 0;
    }

    @include w480 {
      padding: 7rem 0 7rem 0;
    }
  }
}
