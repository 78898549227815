@use "../../../common/styles/_extends.scss" as *;
@use "../../../common/styles/_mixins.scss" as *;

.BaseModal {
  background: var(--main-color-white);
  border: 0.1rem solid #e4e4e7;
  border-radius: 1.2rem;
  box-shadow: 0rem 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.06),
    0rem 0.4rem 0.6rem -0.1rem rgba(0, 0, 0, 0.08);
  padding: 2.4rem 3.2rem 3.6rem 3.2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: fit-content;
  min-height: fit-content;
  height: fit-content;
  max-width: 58.8rem;
  width: 58.8rem;

  @include w768 {
    padding: 2.4rem;
    min-width: 38.4rem;
  }

  @include w480 {
    padding: 2.4rem;
    min-width: 32.8rem;
    max-width: 90%;
  }

  &__header {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
  }

  &__title {
    font: 500 3rem var(--main-font-montserrat);
    line-height: 4.5rem;

    @include w768 {
      font-size: 2.2rem;
    }

    @include w480 {
      font-size: 2.2rem;
    }
  }

  &__icon {
    transition: var(--main-transition) !important;
    color: rgba(26, 26, 26, 0.3);
    cursor: pointer;
  }

  &__icon:hover {
    color: var(--main-color-tangerine);
  }

  &__body {
    @extend %base-scroll;
    margin-top: 3.2rem;

    @include w480 {
      overflow-y: scroll !important;
      padding-right: 1rem;
      max-height: 40rem;
      margin-top: 2rem;
    }
  }

  &__loader {
    display: flex;
    justify-content: center;
  }
}
