@use '../../../../common/styles/_mixins.scss' as *;

.NeedHelp {
  display: grid;
  grid-template-columns: minmax(auto, 86rem);
  row-gap: 2rem;

  &__button {
    width: 26rem;
  }

  &__body {
    @include w480 {
      overflow: hidden;
      overflow-x: scroll;
      margin-top: 1rem;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;

    @include w480 {
      margin-top: 4rem;
    }
  }
}
