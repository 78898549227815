@use '../../../../common/styles/_mixins.scss' as *;

.ProfileNonprofit {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.6rem 4rem;

  @include w1024 {
    gap: 2.6rem 2rem;
  }

  @include w768 {
    gap: 2.8rem 2rem;
  }

  @include w480 {
    grid-template-columns: 1fr;
    gap: 1.6rem 0;
  }

  &>*:nth-child(11),
  &>*:nth-child(12),
  &>*:nth-child(13),
  &>*:nth-child(16),
  &>*:nth-child(17),
  &>*:nth-child(18),
  &>*:nth-child(19),
  &>*:nth-child(20),
  &>*:nth-child(21) {
    grid-column: 1 / 3;
  }

  &>*:nth-child(n) {
    @include w480 {
      grid-column: 1 / 3;
    }
  }

  &__policy_label {
    align-items: baseline;

    @include w480 {
      column-gap: 0;
      font-size: 1.2rem;
    }
  }
}
