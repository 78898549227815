.ProfileRejectPage {

  &__text {
    margin: 10rem auto;
    font-weight: 500;
    text-align: center;
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
