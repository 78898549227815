.Category {
  background: var(--main-color-tangerine);
  border-radius: 4.8rem;
  align-items: center;
  display: flex;
  padding: .5rem 3.9rem .3rem 1.4rem;
  width: fit-content;
  min-width: fit-content;
  max-width: fit-content;
  position: relative;

  &:nth-child(1n+2) {
    margin-left: 1rem;
  }

  &__icon {
    color: var(--main-color-white);
    position: absolute;
    right: 1rem;
  }

  &__text {
    color: var(--main-color-white);
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2.4rem;
    letter-spacing: 0.01em;
    white-space: nowrap;
  }
}
