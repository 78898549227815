@use '../../../common/styles/_mixins.scss' as *;

.AboutPage {
  &__brands {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto auto;
    row-gap: 3.062rem;
    justify-content: space-between;
    margin-top: 6.38rem;

    @include w1024 {
      margin-top: 4rem;
    }

    @include w768 {
      margin-top: 2rem;
    }

    @include w480 {
      margin-top: 1.5rem;
    }
  }

  &__logo_my_buisnes {
    max-width: 30.4rem;
    max-height: 10.7rem;

    @include w768 {
      max-width: 25.6rem;
    }

    @include w480 {
      max-width: 15.4rem;
    }
  }

  &__logo_partner {
    max-width: 10.7rem;
    min-width: 10.7rem;
    margin-right: 4.9rem;

    @include w768 {
      max-width: 10.7rem;
      min-width: 10.7rem;
      margin-right: 2.9rem;
    }

    @include w480 {
      max-width: 4.9rem;
      min-width: 4.9rem;
      margin-right: 1.9rem;
    }
  }

  &__logo_kaliningrad {
    display: grid;
    row-gap: 0.7rem;
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
    max-width: 18.1rem;
    min-width: 18.1rem;

    @include w768 {
      max-width: 15.6rem;
      min-width: 15.6rem;
    }

    @include w480 {
      max-width: 8rem;
      min-width: 8rem;
      row-gap: 0.3rem;
    }

    &_image {
      max-width: 5.9rem;
      min-width: 5.9rem;
      max-height: 6.8rem;
      min-height: 6.8rem;

      @include w1024 {
        max-width: 5.9rem;
        min-width: 5.9rem;
        max-height: 6.8rem;
        min-height: 6.8rem;
      }

      @include w768 {
        max-width: 5.9rem;
        min-width: 5.9rem;
        max-height: 6.8rem;
        min-height: 6.8rem;
      }

      @include w480 {
        max-width: 4.2rem;
        min-width: 4.2rem;
        max-height: 4.8rem;
        min-height: 4.8rem;
      }
    }

    &_text {
      font-weight: 500;
      font-size: 1.1rem;
      line-height: 1.32rem;
      text-align: center;

      @include w768 {
        font-size: 0.8rem;
        line-height: 0.96rem;
      }

      @include w480 {
        font-size: 0.4rem;
        line-height: 0.48rem;
      }
    }
  }

  &__hash_tag {
    font: 700 2.4rem var(--main-font-montserrat);
    line-height: 3.2rem;
    color: #333333;

    @include w768 {
      font-size: 2.2rem;
    }

    @include w480 {
      font-size: 1.8rem;
      grid-column: 1 / 3;
    }
  }

  &__content {
    margin-top: 2rem;
    display: grid;
    row-gap: 3.2rem;

    @include w768 {
      margin-top: 1.4rem;
      row-gap: 1.6rem;
    }

    @include w480 {
      margin-top: 1.4rem;
      row-gap: 1.6rem;
    }
  }

  &__text {
    font: 400 1.6rem var(--main-font-montserrat);
    line-height: 2.6rem;

    @include w768 {
      font-size: 1.4rem;
    }

    @include w480 {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
}
