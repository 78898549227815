@use "../../../common/styles/_extends.scss" as *;
@use "../../../common/styles/_mixins.scss" as *;

.CheckboxList {

  padding: 1.4rem 2.5rem 1.4rem 0rem;

  @include w480 {
    padding: 2.043rem 1.14rem 2.043rem 1rem;
  }

  &__list {
    @extend %base-scroll;
    display: grid;
    grid-template-columns: 1fr;
    padding: 1.1rem 1.6rem;
    max-height: 47.5rem;
    gap: .6rem 0;
    overflow: hidden;
    overflow-y: scroll;

    @include w480 {
      padding: 0;
    }
  }
}
