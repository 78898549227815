@use "../../common/styles/_mixins.scss" as *;
@use "../../common/styles/_extends.scss" as *;

.RequestPreview {
  border: 0.1rem solid #b6b4bf;
  border-radius: 0.8rem;
  // margin: 0 auto;
  padding: 3rem;
  width: 100%;
  max-width: 96rem;
  position: relative;

  @include w1024 {
    padding: 3rem 2rem;
  }

  @include w768 {
    padding: 2rem 1.6rem;
  }

  @include w480 {
    padding: 2rem 1.2rem 6rem 1.2rem;
  }

  &__inner {
    display: grid;
    row-gap: 1.9rem;
    margin: 0 auto;
  }

  // HEADER
  &__header {
    @include w480 {
      display: grid;
      row-gap: 1rem;
    }
  }

  &__info {
    align-items: start;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0 0.5rem;
  }

  &__status {
    margin-top: 1rem;
  }

  /* <-- START - HIDDEN WHEN "ADMIN" VARIANT  --> */

  &__buttons {
    display: grid;
    grid-template-columns: minmax(auto, 14rem) minmax(auto, 11.3rem);
    gap: 0 0.8rem;
    margin-top: 0.2rem;

    @include w480 {
      position: absolute;
      bottom: 2rem;
    }
  }

  &__button {
    @extend %base-button-md-contained;
  }

  &__published {
    @include w480 {
      position: absolute;
      bottom: 2rem;
    }
  }

  /* <-- END - HIDDEN WHEN "ADMIN" VARIANT --> */

  &__title {
    font: 600 1.8rem var(--main-font-montserrat);
    line-height: 2.4rem;
  }

  &__icon {
    min-width: 1.667rem;
    max-width: 1.667rem;
    min-height: 1.867rem;
    max-height: 1.867rem;
  }

  &__contacts {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.8rem;

    @include w480 {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0rem;
    }
  }

  &__contact {
    align-items: center;
    align-content: center;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 0.666rem;
    margin: 0 3.6rem 0.5rem 0;

    @include w768 {
      margin-left: 0 1.6rem 0.5rem 0;
    }

    @include w480 {
      margin: 1rem 1.5rem 0.5rem 0;
    }
  }

  &__contact:nth-child(4) {
    position: absolute;
    top: 2rem;
    right: 0rem;

    @include w1024 {
      top: 3rem;
    }

    @include w768 {
      top: 2rem;
    }
  }

  &__details {
    cursor: pointer;
    color: var(--main-color-blue-bright);
  }

  &__item {
    font: 400 1.4rem var(--main-font-montserrat);
    line-height: 1.54rem;

    @include w768 {
      font-size: 1.2rem;
    }

    @include w480 {
      font-size: 1.2rem;
    }
  }

  // BODY
  &__body {
    background: rgba(230, 230, 230, 0.4);
    border-radius: 0.8rem;
    padding: 1.5rem 1.6rem;
    max-width: 90rem;
  }

  // FOOTER
  &__footer {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__controls_inner {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1rem;
  }

  &__control {
    width: 19.4rem;
    line-height: 1.92rem !important;

    @include w480 {
      width: 18.6rem;
    }
  }

  &__tags {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  &__tag {
    border-radius: 4.8rem;
    padding: 0.4rem 1.4rem;
    max-width: fit-content;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2.4rem;
    margin-right: 1.4rem;
    margin-bottom: 1rem;

    @include w480 {
      margin-right: 1rem;
    }
  }

  &__tag_purple {
    background-color: #f3effe;
    color: #885af8;
  }

  &__tag_tangerine {
    background-color: #fef0ef;
    color: #ed5338;
  }

  &__control_hide {
    opacity: 0;
    cursor: default;
  }
}

.OverflowText {
  font: 400 1.6rem var(--main-font-montserrat);
  line-height: 2.4rem;
  word-wrap: break-word;
  word-break: keep-all;
  white-space: pre-wrap;
  position: relative;

  &::before {
    content: attr(data-label);
    color: var(--main-color-blue-bright);
    cursor: pointer;
    font-size: 1rem;
    position: absolute;
    right: 0;
    bottom: -1.6rem;
  }

  @include w768 {
    font-size: 1.4rem;
  }

  @include w480 {
    font-size: 1.2rem;
  }
}
