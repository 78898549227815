.BaseImage {

  &_loading {
    background: linear-gradient(-45deg, #a5bcdb, #dbbbb6);
    background-size: 400% 400%;
    animation: gradient 2s ease infinite;
  }

  &_hide {
    opacity: 0;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}