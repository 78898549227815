@use '../../../common/styles/mixins' as *;

.OptionItem {
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.16rem;
  transition: var(--main-transition);

  &:hover,
  &_active {
    color: var(--main-color-tangerine);
  }
}
