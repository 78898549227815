@use '../../../common/styles/_mixins.scss' as *;

.BaseDate {

  &_material,
  &_request {
    font: 500 1.4rem var(--main-font-montserrat);
    line-height: 1.8rem;
  }

  &_material {
    color: #B6B4BF;
  }

  &_request {
    @include w480 {
      font-size: 1.2rem;
    }
  }
}
