@use "../../../../../common/styles/_mixins.scss" as *;

.UpdateRequest {

  &__item:nth-child(n) {
    margin-top: 2.4rem;
  }

  &__item:nth-last-child(1) {
    display: grid;
    gap: 1.6rem 0;
  }

  &__textarea>div>textarea {
    min-height: 14.4rem;
    max-height: 14.4rem;
  }

  &__control:nth-child(2) {
    color: var(--main-color-tangerine);
  }
}
