@use "../../common/styles/_mixins.scss" as *;

.OrganizationAdmin {
  max-width: 68rem;

  @include w768 {
    max-width: 46rem;
  }

  @include w480 {
    max-width: 100%;
    padding: 0 1rem;
    margin: 0 auto;
  }

  &__title {
    margin-top: 2.8rem;

    @include w768 {
      margin-top: 2.1rem;
    }
  }

  &__content {
    margin: 4.5rem 0 0 0;

    @include w768 {
      margin: 2.9rem 0 0 0;
    }

    @include w480 {
      margin: 2rem 0 0 0;
    }
  }

  &__buttons {
    margin-top: 5rem;
    column-gap: 2.4rem;
    display: grid;
    grid-template-columns: minmax(auto, 21.3rem) minmax(auto, 18.5rem);

    @include w480 {
      margin-top: 2.3rem;
    }
  }

  &__loader {
    margin-top: 2rem;
  }
}
