@use '../../../common/styles/_mixins.scss' as *;

.Header {
  background-color: var(--main-color-white);
  box-shadow: 0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.04);
  padding: 2.25rem 0 2.15rem 0;
  position: absolute;
  width: 100%;

  // FOR MOBILE
  &_mobile {
    height: 100vh;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 9999;
  }

  &__inner {
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto 1fr;

    @include w480 {
      grid-template-columns: 1fr;
      width: 100%;
    }

    // FOR MOBILE
    &_mobile {
      grid-template-columns: 1fr;
    }
  }

  &__logo_wrapper {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;

    &_mobile {
      padding: 2.25rem 0 2.15rem 0;
    }
  }

  &__logo {
    @include w768 {
      max-width: 10.5rem;
      min-width: 10.5rem;
      max-height: 3.5rem;
      min-height: 3.5rem;
    }

    @include w480 {
      max-width: 10.5rem;
      min-width: 10.5rem;
      max-height: 3.5rem;
      min-height: 3.5rem;
    }
  }

  &__navigation {
    display: flex;
    padding: 0 0 0 6.9rem;

    @include w1024 {
      padding: 0 0 0 6rem;
    }

    @include w768 {
      padding: 0 0 0 4rem;
    }

    @include w480 {
      display: none;
    }
  }

  &__navigation &__item:nth-of-type(1n + 2) {
    margin-left: 4.945rem;

    @include w1024 {
      margin-left: 3.6rem;
    }

    @include w768 {
      margin-left: 2rem;
    }
  }

  &__account {
    @include w480 {
      display: none;
    }
  }

  &__logo {
    width: 16.4rem;
    height: 5.5rem;
  }

  // FOR MOBILE
  &__burger {
    display: none;
    cursor: pointer;

    & > * {
      cursor: pointer;
      max-height: 2.5rem;
      min-height: 2.5rem;
      max-width: 2.5rem;
      min-width: 2.5rem;
    }

    @include w480 {
      display: block;
    }
  }
}
