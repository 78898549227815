$small: 0px;
$medium: 768px;
$large: 1024px;

@mixin w480 {
  @media (min-width: $small) and (max-width: 767px) {
    @content;
  }
}

@mixin w768 {
  @media (min-width: $medium) and (max-width: 1023px) {
    @content;
  }
}

@mixin w1024 {
  @media (min-width: $large) and (max-width: 1439px) {
    @content;
  }
}
