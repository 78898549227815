@use '../../common/styles/_mixins.scss' as *;

.splide {
  position: relative;

  &__image {
    max-height: 49.7rem;
    min-height: 49.7rem;
    border-radius: 0.4rem;

    @include w1024 {
      max-height: 45.1rem;
      min-height: 45.1rem;
    }

    @include w768 {
      max-height: 35.4rem;
      min-height: 35.4rem;
    }

    @include w480 {
      max-height: 21rem;
      min-height: 21rem;
    }
  }

  &__arrows {
    display: flex;
    position: absolute;
    padding: 0 2rem;
    justify-content: space-between;
    top: 45%;
    width: 100%;

    @include w768 {
      top: 40%;
    }
  }

  &__arrow {
    background-color: var(--main-color-white);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 4rem;
    min-height: 4rem;
    max-width: 4rem;
    min-width: 4rem;

    @include w480 {
      max-height: 2.5rem;
      min-height: 2.5rem;
      max-width: 2.5rem;
      min-width: 2.5rem;

      & > * {
        max-height: 1.7rem !important;
        min-height: 1.7rem !important;
        max-width: 1.7rem !important;
        min-width: 1.7rem !important;
      }
    }
  }

  &__arrow:hover > * {
    color: var(--main-color-orange);
  }

  &__sign {
    font: 500 1.6rem var(--main-font-montserrat);
    text-align: center;
    margin-top: 2rem;
    color: #434252;

    @include w768 {
      margin-top: 1rem;
      font-size: 1.6rem;
    }

    @include w480 {
      font-size: 1.2rem;
    }
  }
}
