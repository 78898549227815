@use "../../../../../common/styles/_mixins.scss" as *;

.CreateAppeal {

  &__list {
    display: grid;
    row-gap: 3.2rem;

    @include w480 {
      row-gap: 2rem;
    }
  }

  &__item:nth-child(2) {
    display: grid;
    row-gap: 1.6rem;
  }
}
