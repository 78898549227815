.HeaderAuthed {
  &__avatar {
    border-radius: 50%;
    max-width: 4rem;
    min-width: 4rem;
    max-height: 4rem;
    min-height: 4rem;
    object-fit: cover;
  }

  &__link {
    font-weight: 700;
  }
}
