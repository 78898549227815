@use '../../../common/styles/_mixins.scss' as *;

.BaseTag {
  border: 0.1rem solid var(--main-color-blue-dark);
  transition: var(--main-transition);
  color: var(--main-color-grey-squirrel);
  width: fit-content;
  white-space: nowrap;

  &_common {
    border-color: var(--main-color-tangerine);
    border-radius: 10rem;
    cursor: pointer;
    font: 400 1.4rem var(--main-font-open-sans);
    padding: 0.7rem 1.6rem;

    &:hover,
    &_active {
      background-color: var(--main-color-tangerine);
      color: var(--main-color-white);
    }

    @include w768 {
      font-size: 1.2rem;
    }
  }

  &_preview {
    border-color: var(--main-color-tangerine);
    border-radius: 1.6rem;
    font: 400 1.2rem var(--main-font-open-sans);
    padding: 0.3rem 0.8rem 0.3rem;
    line-height: 1.6rem;

    @include w768 {
      font-size: 1.1rem;
      line-height: 1.5rem;
    }

    @include w480 {
      font-size: 1.23518rem;
      line-height: 1.7rem;
    }
  }
}
