@use '../../../common/styles/_mixins.scss' as *;

.RequestsPage {
  max-width: 96rem;

  &__inner {
    margin: 0;

    @include w1024 {
      max-width: 75.3rem;
    }

    @include w768 {
      max-width: 64.5rem;
    }
  }

  &__header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    @include w480 {
      flex-direction: column;
      row-gap: 2.4rem;
    }
  }

  &__filters {
    margin-top: 3rem;
  }

  &__filter {
    max-width: 25.1rem;
  }

  &__body {
    display: grid;
    margin-top: 3rem;
    row-gap: 2.6rem;

    @include w480 {
      margin-top: 1.6rem;
      row-gap: 1.6rem;
    }
  }

  &__footer {
    margin-top: 5.8rem;
    display: flex;
    justify-content: center;

    @include w480 {
      margin-top: 4.4rem;
    }
  }

  &__button {
    width: 24rem;
  }
}
