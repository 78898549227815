@use "../../common/styles/_extends.scss" as *;
@use "../../common/styles/_mixins.scss" as *;

.Documents {
  border: 0.1rem solid #d7dde6;
  border-radius: 0.8rem;
  padding: 2.2rem 0 2.3rem 1.6rem;
  position: relative;

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem 0;

    @include w480 {
      gap: 1.2rem 0;
    }
  }

  &__label {
    @extend %base-input-label;
    position: absolute;
    top: -0.8rem;
    left: 0.8rem;

    @include w480 {
      font-size: 1.1rem !important;
    }
  }

  &__item,
  &__name,
  &__button_upload {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;

    @include w480 {
      font-size: 1.4rem;
    }
  }

  &__name:hover {
    color: var(--main-color-blue-bright);
  }

  &__button_upload {
    cursor: pointer;
    color: var(--main-color-blue-bright);

    &:hover {
      color: rgba($color: #178ed7, $alpha: 1);
    }
  }

  &__input_upload {
    display: none;
  }

  &__upload {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto auto;
    column-gap: 0.1rem;
  }

  &__icon {
    color: var(--main-color-gray);
    transition: var(--main-transition);
    margin-right: 1rem;
    cursor: pointer;
  }

  &__icon:hover {
    color: var(--main-color-black);
  }
}
