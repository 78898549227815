.Tags {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  &__item:nth-of-type(1n + 2) {
    margin-left: .8rem;
  }

  &__item {
    margin: 0 0 0.8rem 0;
  }
}
