@use '../../../common/styles/_mixins.scss' as *;

.Aside {
  height: 100%;
  border-right: 0.1rem solid #f2f2f2;
  box-shadow: 0px 2px 8px rgba(129, 135, 189, 0.1);

  &__inner {
    padding: 2rem 3rem 0 2.2rem;

    @include w480 {
      padding: 2rem 1rem;
    }
  }

  &__head {
    display: grid;
    min-width: 17.4rem;

    @include w1024 {
      min-width: 17rem;
    }

    @include w768 {
      min-width: 0rem;
    }
  }

  &__logo {
    max-width: 16.4rem;
    max-height: 5.5rem;
    min-width: 16.4rem;
    min-height: 5.5rem;

    @include w1024 {
      max-width: 14.4rem;
      max-height: 4.8rem;
      min-width: 14.4rem;
      min-height: 4.8rem;
    }

    @include w768 {
      max-width: 10.8rem;
      max-height: 3.6rem;
      min-width: 10.8rem;
      min-height: 3.6rem;
    }

    @include w480 {
      max-width: 10.5rem;
      max-height: 3.5rem;
      min-width: 10.5rem;
      min-height: 3.5rem;
    }
  }

  &__text {
    color: #90A0B7;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
    justify-self: end;

    @include w768 {
      font-size: .95rem;
      line-height: 1.77rem;
    }

    @include w480 {
      display: none;
    }
  }

  &__list {
    display: grid;
    row-gap: 1.6rem;
    margin-top: 7.45rem;

    @include w480 {
      display: none;
    }
  }

  &__link {
    align-items: flex-end;
  }

  &__link>*:nth-child(2) {
    margin-right: 1.3rem;
  }
}
