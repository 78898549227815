.RestorePasswordPage {
  &__go_back {
    width: fit-content;
    display: grid;
    align-items: center;
    justify-items: start;
    margin: 0 auto;
    grid-template-columns: auto 1fr;
    column-gap: 0.9rem;
  }

  &__button_send_code {
    cursor: pointer;
  }
}
