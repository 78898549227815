@use '../../../common/styles/_mixins.scss' as *;

.AdminMaterialPage {
  max-width: 68rem;

  @include w1024 {
    max-width: 66.1rem;
  }

  @include w768 {
    max-width: 52rem;
  }

  @include w480 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__header {
    display: grid;
    gap: 2.8rem 0;

    @include w768 {
      gap: 3rem 0;
    }

    @include w480 {
      gap: 2rem 0;
    }
  }

  &__images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 1.1rem;

    @include w480 {
      grid-template-columns: 1fr;
      justify-content: center;
      justify-items: center;
      gap: 2rem 0;
    }
  }

  &__go_back {
    @include w768 {
      font-size: 1.4rem;
    }

    @include w480 {
      font-size: 1.4rem;
    }
  }

  &__form {
    margin-top: 4.6rem;

    @include w768 {
      margin-top: 5rem;
    }

    @include w480 {
      margin-top: 3rem;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2.6rem 0;

    @include w480 {
      gap: 2rem 0;
    }
  }

  &__buttons {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: minmax(auto, 21.3rem) minmax(auto, 18.5rem);
    gap: 0 2.4rem;

    @include w480 {
      gap: 0 1.6rem;
      grid-template-columns: minmax(auto, 17.2rem) minmax(auto, 15.2rem);
      margin-top: 4.4rem;
    }
  }

  &__loader {
    margin-top: 3rem;
  }
}
