@use '../../common/styles/_mixins.scss' as *;
@use '../../common/styles/_extends.scss';

.Select {
  background: var(--main-color-white);
  border: 0.1rem solid var(--main-color-cadet-blue-crayola);
  border-radius: 0.8rem;
  position: relative;
  cursor: pointer;

  // LABEL
  &__label {
    position: absolute;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    left: 1.6rem;
    top: 1.2rem;
    transition: var(--main-transition);

    @include w768 {
      font-size: 1.4rem;
    }

    @include w480 {
      font-size: 1.4rem;
    }
  }

  &__label_active {
    @extend %base-input-label;
    transform: translate(-0.5rem, -2.1rem);

    @include w768 {
      font-size: 1.1rem;
    }

    @include w480 {
      font-size: 1.1rem;
    }
  }

  // CONTROLS
  &__arrow {
    position: absolute;
    right: 1.6rem;
    top: 1.2rem;
    color: var(--main-color-gray);
    z-index: 99;
  }

  // CONTENT
  &__content {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    min-height: 4.6rem;
  }
}
