@use "../../../common/styles/_mixins.scss" as *;

.BaseLabel {
  align-items: center;
  column-gap: 0.67rem;
  display: grid;
  grid-template-columns: auto 1fr;
  cursor: pointer;

  &_disabled {
    display: none;
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;

    @include w768 {
      font-size: 1.4rem;
    }

    @include w480 {
      font-size: 1.2rem;
    }
  }
}
