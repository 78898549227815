.Default {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem 1fr auto;
  grid-template-areas:
    'h h'
    'b b'
    'f f';
  height: 100%;

  &__header {
    grid-area: h;
  }

  &__body {
    grid-area: b;
  }

  &__footer {
    grid-area: f;
  }
}
