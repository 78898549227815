@use '../../../common/styles/_mixins.scss' as *;

.AdminHeader {
  &_filters,
  &_common {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;

    @include w1024 {
      padding-right: 3rem;
    }

    @include w768 {
      padding-right: 3rem;
    }

    @include w480 {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }

  &_filters {
    max-width: 97rem;

    @include w1024 {
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }

    @include w768 {
      padding-right: 1rem;
    }

    @include w480 {
      padding-right: 1rem;
    }
  }

  &__content {
    @include w480 {
      margin-top: 1.2rem;
    }
  }
}
