@use '../../../common/styles/_extends.scss';
@use '../../../common/styles/_mixins.scss' as *;

.Footer {
  background-color: var(--main-color-brown);
  padding: 2.2rem 0 2.3rem 0;

  @include w768 {
    padding: 0.5rem;
  }

  &__inner {
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr auto;

    @include w480 {
      row-gap: 1.6rem;
      grid-template-columns: 1fr;
    }
  }

  &__logo {
    @include w768 {
      max-width: 10.5rem;
      max-height: 3.5rem;
    }

    @include w480 {
      max-width: 10.5rem;
      max-height: 3.5rem;
    }
  }

  &__navigation {
    display: flex;
    padding: 0 0 0 6.9rem;

    @include w1024 {
      padding: 0 0 0 6rem;
    }

    @include w768 {
      padding: 0 0 0 4rem;
    }

    @include w480 {
      padding: 0 0 0 0;
    }
  }

  &__navigation &__item:nth-of-type(1n + 2) {
    margin-left: 4.945rem;

    @include w1024 {
      margin-left: 3.6rem;
    }

    @include w768 {
      margin-left: 2rem;
    }

    @include w480 {
      margin-left: 3.3rem;
    }
  }

  &__additional {
    align-items: center;
    column-gap: 6rem;
    display: grid;
    grid-template-columns: auto auto auto;

    @include w1024 {
      column-gap: 4rem;
    }

    @include w768 {
      column-gap: 3rem;
    }

    @include w480 {
      grid-template-columns: auto auto 1fr;
      align-items: end;
      column-gap: 1.2rem;
    }
  }

  &__logo_kaliningrad {
    display: grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
    gap: 0.6rem 0;

    @include w480 {
      grid-template-columns: auto auto;
      gap: 0 0.7rem;
    }

    & > img {
      max-width: 4.1rem;
      min-width: 4.1rem;
      max-height: 4.1rem;
      min-height: 4.1rem;
    }

    & > p {
      max-width: 9.7rem;
      font-weight: 500;
      font-size: 0.5rem;
      text-align: center;
      line-height: 1rem;
      color: var(--main-color-white);

      @include w480 {
        text-align: left;
      }
    }
  }

  &__logo_ngo {
    max-width: 4.1rem;
  }

  &__year {
    @extend %base-navigation-link;
    color: var(--main-color-white);

    @include w768 {
      font-size: 1.2rem;
      justify-self: end;
    }

    @include w480 {
      justify-self: end;
      font-size: 1.2rem;
    }
  }
}
