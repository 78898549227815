@use '../../../common/styles/extends.scss';
@use '../../../common/styles/_mixins.scss' as *;

.BaseOutlinedInput {
  &_subscription {
    background-color: var(--main-color-white) !important;
    border: none !important;

    & > * {
      border-radius: 0.3rem 0 0 0.3rem !important;
      border: none !important;
    }
  }

  @include w480 {
    font-size: 1.4rem !important;
  }
}
