@use '../../../common/styles/_mixins.scss' as *;

.FormCardTitle {
  font: 400 3.2rem var(--main-font-montserrat);
  line-height: 4.2rem;

  @include w768 {
    font-size: 2.8rem;
  }

  @include w480 {
    font-size: 2.2rem;
  }
}
