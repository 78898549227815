@use "./mixins" as mixins;

%base-navigation-link {
  font: 500 1.4rem var(--main-font-roboto);
  line-height: 1.8rem;
}

%base-form-link {
  font: 400 1.6rem var(--main-font-montserrat);
  line-height: 1.9rem;
}

%base-restore-link {
  font: 400 1.4rem var(--main-font-montserrat);
  line-height: 1.7rem;
}

// scroll
%base-scroll {
  &::-webkit-scrollbar {
    height: 6.1rem;
    width: 0.355rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.1rem rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 0.8rem;
    outline: 0.1rem solid #d9d9d9;
  }
}

// input label (over)
%base-input-label {
  background-color: var(--main-color-white);
  color: var(--main-color-gray);
  font-weight: 500;
  font-size: 1.2rem !important;
  line-height: 1.6rem !important;
  padding: 0 0.25rem !important;
}

// contained button middle
%base-button-md-contained {
  font-size: 1.4rem !important;
  padding-bottom: 0.8rem !important;
  padding-top: 0.8rem !important;
}
