@use '../../../common/styles/mixins' as *;

.FormTab {
  &__label {
    color: var(--main-color-gray-light);
    cursor: pointer;
    background-color: rgba(243, 243, 243, 0.5);
    border-radius: 0.8rem;
    display: block;
    padding: 1rem 0 1rem 0;
    transition: var(--main-transition);
  }

  &__label:hover {
    background-color: rgba($color: #ed5338, $alpha: 0.2);
    color: var(--main-color-white);
  }

  &__name {
    line-height: 2.4rem;
    font: 500 1.6rem var(--main-font-montserrat);

    @include w480 {
      font-size: 1.4rem;
    }
  }

  &__input {
    display: none;
  }

  &_active {
    background-color: var(--main-color-tangerine);
    color: var(--main-color-white);
  }
}
