@use "../../common/styles/mixins" as *;

.ImagePreview {
  &__inner {
    width: 21.9rem;
    height: 13rem;
    position: relative;

    @include w768 {
      width: 16.7rem;
      height: 11.4rem;
    }

    @include w480 {
      height: 17rem;
      width: 33.8rem;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0;
  }

  &_button_delete {
    color: #db2140;
    cursor: pointer;
    margin-top: 0.8rem;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.44rem;
  }
}
