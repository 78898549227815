@use '../../../../../common/styles/_mixins.scss' as *;

.CreateRequest {

  &__subtitle {
    font: 500 1.6rem var(--main-font-montserrat);
    line-height: 2.4rem;
  }

  &__item:nth-child(n) {
    margin-top: 2.5rem;
  }

  &__item:nth-child(3)>div>div>textarea {
    min-height: 14.4rem;
    max-height: 14.4rem;
  }

  &__item:nth-last-child(1) {
    display: grid;
    gap: 1.6rem 0;
  }
}
