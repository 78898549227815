@use "../../../common/styles/_mixins.scss" as *;

.SignupPage {
  @include w480 {
    max-width: 22rem;
    margin: 0 auto;
  }

  &__row {
    @include w480 {
      margin-top: 3rem;
    }
  }
}
