@use '../../../common/styles/_mixins.scss' as *;

.BasePage {
  // padding: 5.862rem 0 19.1rem 0;
  padding: 8.63rem 0 19.1rem 0;

  @include w1024 {
    padding: 6.8rem 0 15.2rem 0;
  }

  @include w768 {
    padding: 5rem 0 8.2rem 0;
  }

  @include w480 {
    padding: 4rem 0 8.2rem 0;
  }
}
