@use '../../../common/styles/extends.scss';
@use '../../../common/styles/mixins.scss' as *;

.BaseLink {
  transition: var(--main-transition);
  display: inline-flex;

  &_mobile_menu,
  &_form {
    @extend %base-form-link;
    color: var(--main-color-black);
  }

  &_footer,
  &_header {
    @extend %base-navigation-link;

    @include w768 {
      font-size: 1.2rem;
    }

    @include w480 {
      font-size: 1.1rem;
    }
  }

  &_footer {
    color: var(--main-color-white);
  }

  &_arrow,
  &_material {
    @extend %base-form-link;
    color: var(--main-color-blue-bright) !important;
  }

  &_restore {
    @extend %base-restore-link;
    color: var(--main-color-blue-bright);
  }

  &_mobile_menu {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.4rem;
  }

  //ACTIVE AND HOVER
  &_active {
    color: var(--main-color-tangerine);
  }

  &_arrow:hover,
  &_mobile_menu:hover,
  &_material:hover,
  &_header:hover,
  &_footer:hover {
    color: rgba($color: #ed5338, $alpha: 0.5) !important;
  }

  &_restore:hover,
  &_form:hover,
  &_material:hover {
    color: var(--main-color-tangerine) !important;
    text-decoration: underline !important;
  }
}
