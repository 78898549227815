@use "../../common/styles/_mixins.scss" as *;

.Table {

  // HEAD
  &__head_row {}

  &__head_cell {
    border: none !important;
    padding-bottom: 1.2rem !important;
    padding-top: 2.4rem !important;
    font: 500 1.2rem var(--main-font-montserrat) !important;
    line-height: 1.6rem !important;
    color: #5F5F5F !important;
    white-space: nowrap !important;
  }

  // BODY
  &__body {
    border-radius: 0.8rem !important;
    border-collapse: separate !important;
    outline: 0.1rem solid #e4e4e7 !important;
    border: 0.3rem solid rgba(237, 83, 56, 0) !important;
  }

  &__row {
    // cursor: pointer;
    transition: var(--main-transition);

    &:hover {
      background-color: rgba(237, 83, 56, 0.15);
    }
  }

  &__cell,
  &__cell>* {
    color: var(--main-color-graphite-black) !important;
    font: 500 1.4rem var(--main-font-montserrat) !important;
    line-height: 2rem;
    word-wrap: break-word !important;
    word-break: keep-all !important;
  }

  // NO ENTRIES
  &__cell_no_entries {
    color: var(--main-color-gray) !important;
    font: 500 2rem var(--main-font-montserrat) !important;
    line-height: 2.2rem !important;
    text-align: center !important;
  }

  // LOADER
  &__cell_no_entries,
  &__cell_loader {
    border: none !important;
    text-align: center !important;
  }
}
