@use '../../../common/styles/_funcs.scss' as *;
@use '../../../common/styles/_mixins.scss' as *;

.BaseContainer {
  margin: 0 auto;
  max-width: 144rem;
  min-height: 100%;
  padding: 0 rem(5.4rem, 144rem) 0 rem(11rem, 144rem);

  @include w1024 {
    padding: 0 3rem 0 3rem;
  }

  @include w768 {
    padding: 0 2rem 0 2rem;
  }

  @include w480 {
    padding: 0 1.1rem 0 1.1rem;
  }
}
