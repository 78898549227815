@use '../../../common/styles/_mixins.scss' as *;

.AdminRequestsPage {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  row-gap: 2rem;

  // BODY
  &__body {
    display: grid;
    gap: 1.02rem 0;
  }

  &__switches {
    @include w480 {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }

  &__button {
    width: 25.9rem;

    @include w768 {
      width: 17.4rem;
      font-size: 1.4rem !important;
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }

    @include w480 {
      width: 17.4rem;
      font-size: 1.4rem !important;
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
  }

  &__tabs {
    @include w1024 {
      overflow: hidden;
      overflow-x: scroll;
      padding-right: 3rem;
    }

    @include w768 {
      overflow: hidden;
      overflow-x: scroll;
      padding-right: 3rem;
    }

    @include w480 {
      overflow: hidden;
      overflow-x: scroll;
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }

  &__tab {
    @include w1024 {
      width: 108rem;
    }

    @include w768 {
      width: 108rem;
    }

    @include w480 {
      width: 108rem;
    }
  }

  // TABLE BUTTONS FOR FIRST TAB
  &__buttons {
    display: flex;
    flex-direction: column;

    &_top > * {
      font-size: 1.4rem !important;
      padding-top: 0.8rem !important;
      padding-bottom: 0.8rem !important;
    }

    &_top > *:nth-child(2) {
      margin-left: 1rem;
    }

    &_bot > * {
      font-size: 1.2rem !important;
      color: rgba(237, 83, 56, 0.6) !important;
      padding: 0 !important;
    }

    &_bot {
      margin-top: 0.2rem;
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 1rem;
      justify-items: start;
      justify-content: start;
    }
  }

  // TABLE BUTTONS FORM SECOND TAB
  &__controls {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 1.8rem;
  }

  &__control {
    cursor: pointer;
    max-height: 2rem;
    min-height: 2rem;
    max-width: 2rem;
    min-width: 2rem;
    transition: var(--main-transition);
  }

  &__control:hover {
    opacity: 0.4;
  }

  &__link {
    display: block;
  }
}
