// Roboto
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-BlackItalic.eot');
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
    url('../fonts/roboto/Roboto-BlackItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/roboto/Roboto-BlackItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-BlackItalic.woff') format('woff'),
    url('../fonts/roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Bold.eot');
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('../fonts/roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Bold.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Bold.woff') format('woff'),
    url('../fonts/roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-BoldItalic.eot');
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('../fonts/roboto/Roboto-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/roboto/Roboto-BoldItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-BoldItalic.woff') format('woff'),
    url('../fonts/roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-LightItalic.eot');
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('../fonts/roboto/Roboto-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/roboto/Roboto-LightItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-LightItalic.woff') format('woff'),
    url('../fonts/roboto/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Regular.eot');
  src: local('Roboto'), local('Roboto-Regular'),
    url('../fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Regular.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Regular.woff') format('woff'),
    url('../fonts/roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-ThinItalic.eot');
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('../fonts/roboto/Roboto-ThinItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/roboto/Roboto-ThinItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-ThinItalic.woff') format('woff'),
    url('../fonts/roboto/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Black.eot');
  src: local('Roboto Black'), local('Roboto-Black'),
    url('../fonts/roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Black.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Black.woff') format('woff'),
    url('../fonts/roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-MediumItalic.eot');
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('../fonts/roboto/Roboto-MediumItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/roboto/Roboto-MediumItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-MediumItalic.woff') format('woff'),
    url('../fonts/roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Thin.eot');
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('../fonts/roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Thin.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Thin.woff') format('woff'),
    url('../fonts/roboto/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Light.eot');
  src: local('Roboto Light'), local('Roboto-Light'),
    url('../fonts/roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Light.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Light.woff') format('woff'),
    url('../fonts/roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Italic.eot');
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('../fonts/roboto/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Italic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Italic.woff') format('woff'),
    url('../fonts/roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Medium.eot');
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../fonts/roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/roboto/Roboto-Medium.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Medium.woff') format('woff'),
    url('../fonts/roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

// Montserrat
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-ExtraLight.eot');
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
    url('../fonts/montserrat/Montserrat-ExtraLight.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-ExtraLight.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-ExtraLight.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-MediumItalic.eot');
  src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
    url('../fonts/montserrat/Montserrat-MediumItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-MediumItalic.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-MediumItalic.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Italic.eot');
  src: local('Montserrat Italic'), local('Montserrat-Italic'),
    url('../fonts/montserrat/Montserrat-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-Italic.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-Italic.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../fonts/montserrat/Montserrat-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-Regular.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-ThinItalic.eot');
  src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
    url('../fonts/montserrat/Montserrat-ThinItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-ThinItalic.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-ThinItalic.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Bold.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('../fonts/montserrat/Montserrat-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-Bold.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Light.eot');
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url('../fonts/montserrat/Montserrat-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-Light.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-Light.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Black.eot');
  src: local('Montserrat Black'), local('Montserrat-Black'),
    url('../fonts/montserrat/Montserrat-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-Black.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-Black.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Medium.eot');
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('../fonts/montserrat/Montserrat-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-Medium.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-Medium.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-ExtraLightItalic.eot');
  src: local('Montserrat ExtraLight Italic'),
    local('Montserrat-ExtraLightItalic'),
    url('../fonts/montserrat/Montserrat-ExtraLightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-ExtraLightItalic.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-ExtraLightItalic.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-ExtraLightItalic.ttf')
      format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-BlackItalic.eot');
  src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
    url('../fonts/montserrat/Montserrat-BlackItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-BlackItalic.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-BlackItalic.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-ExtraBoldItalic.eot');
  src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
    url('../fonts/montserrat/Montserrat-ExtraBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-ExtraBoldItalic.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-SemiBoldItalic.eot');
  src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
    url('../fonts/montserrat/Montserrat-SemiBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-SemiBoldItalic.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-ExtraBold.eot');
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url('../fonts/montserrat/Montserrat-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-ExtraBold.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-BoldItalic.eot');
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
    url('../fonts/montserrat/Montserrat-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-BoldItalic.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-BoldItalic.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-LightItalic.eot');
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
    url('../fonts/montserrat/Montserrat-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-LightItalic.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-LightItalic.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-SemiBold.eot');
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url('../fonts/montserrat/Montserrat-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-SemiBold.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Thin.eot');
  src: local('Montserrat Thin'), local('Montserrat-Thin'),
    url('../fonts/montserrat/Montserrat-Thin.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-Thin.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-Thin.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

// Open sans
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-Italic.eot');
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('../fonts/open-sans/OpenSans-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/open-sans/OpenSans-Italic.woff2') format('woff2'),
    url('../fonts/open-sans/OpenSans-Italic.woff') format('woff'),
    url('../fonts/open-sans/OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-Regular.eot');
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../fonts/open-sans/OpenSans-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/open-sans/OpenSans-Regular.woff2') format('woff2'),
    url('../fonts/open-sans/OpenSans-Regular.woff') format('woff'),
    url('../fonts/open-sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-BoldItalic.eot');
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
    url('../fonts/open-sans/OpenSans-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/open-sans/OpenSans-BoldItalic.woff2') format('woff2'),
    url('../fonts/open-sans/OpenSans-BoldItalic.woff') format('woff'),
    url('../fonts/open-sans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-SemiBold.eot');
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('../fonts/open-sans/OpenSans-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/open-sans/OpenSans-SemiBold.woff2') format('woff2'),
    url('../fonts/open-sans/OpenSans-SemiBold.woff') format('woff'),
    url('../fonts/open-sans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-ExtraBoldItalic.eot');
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
    url('../fonts/open-sans/OpenSans-ExtraBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/open-sans/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
    url('../fonts/open-sans/OpenSans-ExtraBoldItalic.woff') format('woff'),
    url('../fonts/open-sans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-LightItalic.eot');
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
    url('../fonts/open-sans/OpenSans-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/open-sans/OpenSans-LightItalic.woff2') format('woff2'),
    url('../fonts/open-sans/OpenSans-LightItalic.woff') format('woff'),
    url('../fonts/open-sans/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-Bold.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../fonts/open-sans/OpenSans-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/open-sans/OpenSans-Bold.woff2') format('woff2'),
    url('../fonts/open-sans/OpenSans-Bold.woff') format('woff'),
    url('../fonts/open-sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-SemiBoldItalic.eot');
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
    url('../fonts/open-sans/OpenSans-SemiBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/open-sans/OpenSans-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/open-sans/OpenSans-SemiBoldItalic.woff') format('woff'),
    url('../fonts/open-sans/OpenSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-ExtraBold.eot');
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
    url('../fonts/open-sans/OpenSans-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/open-sans/OpenSans-ExtraBold.woff2') format('woff2'),
    url('../fonts/open-sans/OpenSans-ExtraBold.woff') format('woff'),
    url('../fonts/open-sans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/open-sans/OpenSans-Light.eot');
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url('../fonts/open-sans/OpenSans-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/open-sans/OpenSans-Light.woff2') format('woff2'),
    url('../fonts/open-sans/OpenSans-Light.woff') format('woff'),
    url('../fonts/open-sans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

// LATO
@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/Lato-SemiboldItalic.eot');
  src: local('Lato Semibold Italic'), local('Lato-SemiboldItalic'),
    url('../fonts/lato/Lato-SemiboldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/lato/Lato-SemiboldItalic.woff') format('woff'),
    url('../fonts/lato/Lato-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/Lato-Semibold.eot');
  src: local('Lato Semibold'), local('Lato-Semibold'),
    url('../fonts/lato/Lato-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lato/Lato-Semibold.woff') format('woff'),
    url('../fonts/lato/Lato-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/Lato-MediumItalic.eot');
  src: local('Lato Medium Italic'), local('Lato-MediumItalic'),
    url('../fonts/lato/Lato-MediumItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/lato/Lato-MediumItalic.woff') format('woff'),
    url('../fonts/lato/Lato-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/Lato-Medium.eot');
  src: local('Lato Medium'), local('Lato-Medium'),
    url('../fonts/lato/Lato-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lato/Lato-Medium.woff') format('woff'),
    url('../fonts/lato/Lato-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/Lato-Regular.eot');
  src: local('Lato Regular'), local('Lato-Regular'),
    url('../fonts/lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lato/Lato-Regular.woff') format('woff'),
    url('../fonts/lato/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
