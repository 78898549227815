@use '../../common/styles/_mixins.scss' as *;

.MaterialPreview {
  border: 0.1rem solid #e0e0e0;
  border-radius: 0.8rem;
  max-width: 35.8rem;
  min-width: 35.8rem;
  max-height: 31.7rem;
  min-height: 31.7rem;
  display: grid;
  grid-template-rows: auto 1fr;

  @include w480 {
    max-width: 34rem;
    min-width: 34rem;
  }

  &__header,
  &__image {
    max-height: 17.634rem;
    min-height: 17.634rem;
    background-color: var(--main-color-grey);
  }

  &__title {
    font: 700 1.7rem var(--main-font-montserrat);
    line-height: 2.4rem;

    @include w768 {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }

    @include w480 {
      line-height: 2.5rem;
    }
  }

  &__not_found_image,
  &__image {
    border-radius: 0.8rem 0.8rem 0 0;
  }

  &__not_found_image {
    align-items: center;
    background-color: #D9D9D9;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  &__not_found_text {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: var(--main-color-gray);
  }

  &__body {
    display: grid;
    grid-template-rows: 1fr auto;
    padding: 2.3rem 1.4rem 1.6rem 2rem;
  }

  &__item:nth-child(2) {
    margin-top: 1rem;
    align-items: center;
    display: flex;
  }

  &__date {
    font-size: 1.2rem;
  }
}
