:root {
  // COLORS
  --main-color-orange-light: #ff9100;
  --main-color-orange: #f2994a;
  --main-color-black: #000000;
  --main-color-white: #ffffff;
  --main-color-blue: #394c6b;
  --main-color-blue-light: #d7dde5;
  --main-color-blue-bright: #109cf1;
  --main-color-blue-so-light: #c2cfe0;
  --main-color-blue-so-dark: #334d6e;
  --main-color-blue-dark: #2f80ed;
  --main-color-gray-so-light: #d7dde6;
  --main-color-gray-light: #99a8bd;
  --main-color-gray: #868686;
  --main-color-red: #f7685b;
  --main-color-yellow: #ffed46;
  --main-color-green: #177943;
  --main-color-tangerine: #ed5338;
  --main-color-brown: #623b2a;
  --main-color-graphite-black: #1A1A1A;
  --main-color-grey-squirrel: #797888;
  --main-color-cadet-blue-crayola: #B6B4BF;

  // FONT-FAMILIES
  --main-font-open-sans: 'Open Sans', sans-serif;
  --main-font-montserrat: 'Montserrat', sans-serif;
  --main-font-roboto: 'Roboto', sans-serif;
  --main-font-lato: 'Lato', sans-serif;

  // TRANSITION
  --main-transition: 0.15s;
}
