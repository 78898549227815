@use "../../common/styles/extends" as *;
@use "../../common/styles/mixins" as *;

.CropModalBody {
  overflow-y: unset !important;
}

.CropModal {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  gap: 3.2rem 0;

  @include w480 {
    gap: 2rem 0;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 3rem;

    @include w480 {
      font-size: 1.4rem;
    }
  }

  &__content {
    @extend %base-scroll;
    max-height: 30rem;
    overflow-y: auto;
    padding-right: 0.5rem;
  }

  &__image_wrapper {
    border-radius: 0.8rem;
  }

  &__image {
    max-height: 60rem !important;
    width: 52.2rem;
  }
}
