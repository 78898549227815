.MailingForm {
  display: grid;
  gap: 2.5rem 0;

  &__item:nth-last-child(2) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__item:nth-last-child(1) {
    display: grid;
    gap: 1.6rem 0;
  }
}
