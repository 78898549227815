.RequestStatus {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  column-gap: 1.2rem;

  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;

  &__icon {
    max-height: 0.6rem !important;
    min-height: 0.6rem !important;
    max-width: 0.6rem !important;
    min-width: 0.6rem !important;
  }
}
