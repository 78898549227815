@use "../../../common/styles/_mixins.scss" as *;

.AdminAppealsPage {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem 0;

  &__body {
    display: grid;
    gap: 2.6rem 0;
  }

  &__tabs,
  &__body {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__header {
    min-height: 4.8rem;

    @include w768 {
      display: grid;
      grid-template-columns: 1fr;
      min-height: auto;
    }

    @include w480 {
      display: grid;
      grid-template-columns: 1fr;
      min-height: auto;
    }
  }

  &__filters {
    display: grid;
    // grid-template-columns: 17rem 24rem 17rem;
    grid-template-columns: repeat(3, auto);
    gap: 0 2rem;

    @include w1024 {
      gap: 0 1rem;
      justify-content: start;
    }

    @include w768 {
      grid-template-columns: auto 1fr;
      margin-top: 2rem !important;
      gap: 1rem;
    }

    @include w480 {
      grid-template-columns: 1fr;
      row-gap: 2rem;
      margin-top: 1.2rem !important;
    }
  }

  &__filter:nth-child(1),
  &__filter:nth-child(3) {
    width: 20rem;
  }

  &__filter:nth-child(2) {
    width: 26rem;
  }

  &__filter:nth-child(1),
  &__filter:nth-child(2),
  &__filter:nth-child(3) {
    @include w480 {
      width: 100%;
    }
  }
}
