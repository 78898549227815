.HeaderNoAuthed {
  display: flex;

  &__item:nth-of-type(1n + 2) {
    margin-left: 2.775rem;
  }

  &__link {
    font-weight: 700;
  }
}
