*,
::after,
::before {
  box-sizing: border-box;
  font-size: 62.5%;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  color: var(--main-color-graphite-black);
  font-family: var(--main-font-montserrat);

  margin-bottom: calc(env(keyboard-inset-height));
}

#root {
  height: 100%;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  white-space: pre-wrap;
  word-break: keep-all;
}

a {
  text-decoration: none;
  color: var(--main-color-black);
}

img {
  width: 100%;
  height: auto;
}
