.BaseGoBack {
  align-items: center;
  cursor: pointer;
  display: inline-flex;

  &__icon {
    margin-right: 1.5rem;
    max-height: 1.5rem !important;
    min-height: 1.5rem !important;
    max-width: 1.5rem !important;
    min-width: 1.5rem !important;
  }

  &:hover>*>*,
  &:hover>*>* {
    transition: var(--main-transition);
    color: var(--main-color-tangerine);
  }
}
