@use '../../../common/styles/_mixins.scss' as *;

.ProfileActions {
  @include w1024 {
    max-width: 75.3rem !important;
  }

  @include w768 {
    max-width: 100% !important;
  }
}
